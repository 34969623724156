import { useEffect, useState } from 'react';

export const useResize = () => {
  const checkSize = (val) => (val < 640 ? '640' : val < 1024 ? '1024' : '1600');
  const [size, setSize] = useState(
    checkSize(window.document.documentElement.offsetWidth),
  );

  useEffect(() => {
    const handleWidth = () => {
      setSize(checkSize(window.document.documentElement.offsetWidth));
    };
    window.addEventListener('resize', handleWidth);

    return () => {
      window.addEventListener('resize', handleWidth);
    };
  }, []);

  return { size: `images${size}` };
};
