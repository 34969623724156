import 'aos/dist/aos.css';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { darkTheme } from './theme/darkTheme';
import { GlobalStyled } from './theme/GlobalStyle';
import { ThemeProvider } from 'styled-components';

import { AboutMe } from './components/organisms/pages/aboutMe/AboutMePage';
import { CategoriesPage } from './components/organisms/pages/categories/CategoriesPage';
import { Category } from './components/organisms/pages/category/Category';
import { Contact } from './components/organisms/pages/contact/Contact';
import { Faq } from './components/organisms/pages/faq/Faq';
import { Home } from './components/organisms/pages/home/Home';
import { RecordPage } from './components/organisms/pages/record/RecordPage';

//https://www.filemail.com/d/iozafxtxrdsqdkt
//https://www.filemail.com/d/evudmapzugnjumr
//https://www.filemail.com/d/mdpkxhmzjwlmmhi

//https://www.filemail.com/t/gVHtosi2 13.12.22

export const App = () => {
  return (
    <BrowserRouter>
      <ThemeProvider theme={darkTheme}>
        <GlobalStyled />
        <Switch>
          <Route path={'/'} exact component={Home} />
          <Route path={'/o mnie'} exact component={AboutMe} />
          <Route path={'/FAQ'} exact component={Faq} />
          <Route path={'/kontakt'} exact component={Contact} />
          <Route path={'/kategoria/'} exact component={CategoriesPage} />
          <Route path={'/kategoria/:category'} exact component={Category} />
          <Route
            path={'/kategoria/:category/:title'}
            exact
            component={RecordPage}
          />
        </Switch>
      </ThemeProvider>
    </BrowserRouter>
  );
};
