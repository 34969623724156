import styled, { css } from 'styled-components';

export const ImgWrap = styled.div(
  ({ url }) => css`
    background-image: url(${url});
    background-position: center;
    background-size: cover;
    height: 50vh;
    width: 100%;
  `,
);

export const Header = styled.header`
  > * {
    text-align: center;
    padding: 10px;
  }
`;

export const HeaderTitle = styled.h1`
  padding: 54px 0 16px;
`;

export const HeaderSubtitle = styled.h3``;

export const HeaderText = styled.p``;

export const InfoWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 120px 4px 78px;
`;

export const InfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  text-align: center;
  margin: 12px 0;
`;

export const InfoPrefix = styled.p`
  font-size: 1.1rem;
  margin: 10px;
`;
export const InfoText = styled.strong`
  font-size: 1.1rem;
  transition: 0.4s;

  :hover {
    transform: scale(1.1);
  }
`;

export const InfoAnchor = styled.a(
  ({ theme: { colors } }) => css`
    cursor: pointer;
    font-weight: bold;
    text-decoration: underline ${colors.secondary};
    transition: 0.4s;

    :hover {
      transform: scale(1.1);
    }
  `,
);
