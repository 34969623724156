import { AppLayout } from '../../../molecules/layouts/AppLayout';
import { categories } from '../../../../constatns/categories';
import { ImageNav } from '../../../molecules/nav/imageNav/ImageNav';
import { NotFound } from '../notFound/NotFound';
import { useParams } from 'react-router-dom';
import { useResize } from '../../../../hooks/useResize';
import {
  Article,
  Gap,
  Header,
  HeaderImg,
  HeaderImgWrap,
  HeaderWrap,
  Img,
  Section,
} from './RecordPage.styled';

export const RecordPage = () => {
  const { category, title } = useParams();
  const { size } = useResize();

  const record = categories
    .filter((el) => el.category === category)
    ?.filter((el) => el.title === title)[0];

  if (!record) return <NotFound />;

  return (
    <AppLayout>
      <Article>
        <HeaderWrap>
          <HeaderImgWrap>
            <HeaderImg
              src={record[size][record.mainImg]}
              alt={`image ${category} - ${title}`}
              loading={'lazy'}
            />
          </HeaderImgWrap>
          <Header>{record.title}</Header>
        </HeaderWrap>

        <Gap />

        {record[size]
          .filter((path) => path !== record[size][record.mainImg])
          .map((el, i) => (
            <Section key={el + i}>
              <Img
                loading={'lazy'}
                src={el}
                alt={`image ${category} - ${title}`}
              />
            </Section>
          ))}
      </Article>
      <ImageNav />
    </AppLayout>
  );
};
