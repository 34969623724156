import styled, { css } from 'styled-components';

export const Header = styled.header(
  ({ src }) => css`
    background-image: url(${src});
    background-position: center;
    background-size: cover;
    height: 100vh;
    width: 100%;
  `,
);
