import { AppLayout } from '../../../molecules/layouts/AppLayout';
import { categories } from '../../../../constatns/categories';
import { NotFound } from '../notFound/NotFound';
import { RecordsGrid } from '../../../molecules/recordsGrid/RecordsGrid';
import { Title } from './Category.styled';
import { useParams } from 'react-router-dom';

export const Category = () => {
  const { category } = useParams();

  const selectedCategories = categories.filter(
    (el) => el.category === category,
  );

  if (selectedCategories.length === 0) return <NotFound />;

  return (
    <AppLayout>
      <Title>{category}</Title>
      <RecordsGrid categories={selectedCategories} />
    </AppLayout>
  );
};
