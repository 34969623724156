import { aboutMeTexts } from '../../../constatns/aboutMeTexts';
import { authorBgcImg } from '../../../constatns/backgrounds';
import { useResize } from '../../../hooks/useResize';
import { Text, TextHeader, TextWrap, Wrap } from './AboutMe.styled';

export const AboutMe = () => {
  const { size } = useResize();

  return (
    <Wrap img={authorBgcImg[0][size][0]} alt={'background image'}>
      <TextWrap>
        <TextHeader> {aboutMeTexts.author} </TextHeader>
        <Text>{aboutMeTexts.text1}</Text>
        <Text>{aboutMeTexts.text2}</Text>
      </TextWrap>
    </Wrap>
  );
};
