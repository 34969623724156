export const faqTexts = [
  {
    title: 'Gdzie wykonuje zdjęcia ?',
    text: 'Na Podhalu, uwielbiam zdjęcia na łonie natury, jednak fotografia nie znosi rutyny i zdjęcia wykonuję wszędzie tam, gdzie jestem lub zostanę zaproszony. Większość zleceń wykonuję na terenie małopolski . Uwielbiam podróżować, więc jestem otwarty na propozycje zleceń w odleglejszych okolicach. Dochodzą tu jednak dodatkowo koszty podróży i zakwaterowania.',
  },
  {
    title: 'Jakim sprzętem dysponuje?',
    text: 'Pracuję na pełno klatkowych profesjonalnych aparatach marki Nikon. Posiadam dwa body. Nikon D750 i Nikon D610 oraz obiektywy Sigma art 35 1.4  i Sigma 70-200mm f/2.8.',
  },
  {
    title: 'Jaką fotografią się zajmuje?',
    text: 'Zdjęcia, które tworzę w większości są szeroko pojętą fotografią reporterską. Jak jednak widać w portfolio- zakres moich prac jest bardzo szeroki: reportaż, portrety, pejzaż, fotografia okolicznościowa. Rozwijam się w każdej dziedzinie, ale doświadczenie w fotografii reporterskiej jest dla mnie najcenniejsze. Dzięki „dobremu oku i ręce” (czyli wyczuciu i refleksowi) udaje mi się uwieczniać interesujące chwile i ich obrazy. Z czego zarówno klienci, jak i ja- jesteśmy zadowoleni.',
  },
  {
    title: 'Nie lubisz zdjęć. Jak sobie z tym poradzę? ',
    text: 'Bardzo często słyszę to od ludzi. Jednak zawsze staram się wprowadzić luźną i komfortową atmosferę, zanim zacznę pstrykać zdjęcia. Chodzi o naturalność i prawdziwe emocje.',
  },
  {
    title: 'Czy możemy się spotkać',
    text: 'Jasne, zapraszam na kawę i na przeglądnięcie zdjęć, tak żeby porozmawiać i lepiej się poznać.',
  },
  {
    title: 'Jak długo czeka się  na odbiór zdjęć?  ',
    text: 'Termin wynosi 2-3 tygodnie, natomiast jeśli jest to impreza okolicznościowa to maksymalnie miesiąc.',
  },
];
