import { Container, LayoutWrapper } from './AppLayout.styled';
import { Footer } from '../../atoms/footer/Footer';
import { MainNavigation } from '../nav/mainNav/MainNavigation';

export const AppLayout = ({ children }) => {
  return (
    <Container>
      <MainNavigation />
      <LayoutWrapper>{children}</LayoutWrapper>
      <Footer />
    </Container>
  );
};
