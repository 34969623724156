import { importAll } from '../helpers/images';

export const contactData = {
  email: 'thajnos@gmail.com',
  tel: '536 095 566',
  facebook: 'https://www.facebook.com/tomaszhajnosfotografia',
  instagram: 'https://www.instagram.com/tomasz.hajnos.fotografia',
  address: 'Kowaniec 93 Nowy Targ',
};

export const aboutMeTexts = {
  author: 'Tomasz Hajnos',
  email: contactData.email,

  images640: importAll(
    require.context('../assets/author/640x480', false, /\.(png|jpe?g|svg)$/),
  ),
  images1024: importAll(
    require.context('../assets/author/1024x768', false, /\.(png|jpe?g|svg)$/),
  ),
  images1600: importAll(
    require.context('../assets/author/1600x720', false, /\.(png|jpe?g|svg)$/),
  ),

  title: 'O mnie',
  text1:
    'Cześć, tu Tomek. Mieszkam na Podhalu, a dokładnie w Nowym Targu, który jest idealną górską bazą wypadową. Właśnie w górach zaczęła się moja przygoda z fotografią, doskonale pamiętam swój pierwszy wypad ze znajomymi na zachód słońca w Tatry… ',
  text2:
    'Od tamtego momentu aparat stał się moim kompanem na górskich wycieczkach i sposobem zapisywania wspomnień. W tym momencie dzielę się moją pasją z Wami, tak aby Wasze piękne chwile zostały uwiecznione na lata. ',
  text3: '',
  text4: '',
  text5: '',
  text6: '',

  quote:
    '„Panuj  nad sobą, szukaj piękna, kochaj to co piękne, proste i czyste. Otaczaj się tym co piękne, proste i czyste.”',
  authorQuote: 'Cytat z filmu „W cieniu Everestu”',
};
