import styled from 'styled-components';

export const Wrap = styled.section`
  border-radius: 8px;
  border: 1px solid gray;
  box-sizing: content-box;
  margin: 16px;
  width: 300px;

  @media (max-width: 360px) {
    margin: 16px 8px;
  }
`;

export const ImgWrap = styled.a`
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  cursor: pointer;
  display: block;
  height: 300px;
  overflow: hidden;
  width: 300px;
`;

export const Img = styled.div`
  background-image: url(${(props) => props.img});
  background-position: center;
  background-size: cover;
  height: 100%;
  overflow: hidden;
  transition: 0.3s;
  width: 100%;

  &:hover {
    transform: scale(1.1);
  }
`;

export const TextWrap = styled.div`
  padding: 22px 12px;
`;

export const TextHeader = styled.h2`
  text-align: center;
  padding: 4px 0;
`;
