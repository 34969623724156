import styled from 'styled-components';

export const NotFoundContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-content: space-between;
`;

export const NotFoundWrap = styled.main`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NotFoundText = styled.p`
  font-size: 26px;
  padding-bottom: 4px;
`;
