import { contactData } from '../../../../constatns/aboutMeTexts';
import { Hamburger } from './Hamburger/Hamburger';
import { mainLinks } from '../../../../constatns/links';
import { NavLink } from 'react-router-dom';
import { useEffect, useState } from 'react';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import {
  LogoImg,
  MNContactContainer,
  MNContactHeader,
  MNContactText,
  MNContactWrap,
  MNContactWrapIcon,
  MNContactWrapIcons,
  MNItem,
  MNItemList,
  MNItemWrap,
  MNOverlay,
  MNWrap,
} from './MainNavigation.styled';

export const MainNavigation = () => {
  const [activeNav, setActiveNav] = useState(false);

  const createLink = (path, name, delay) => (
    <MNItemWrap key={path}>
      <MNItem delay={delay}>
        <NavLink
          to={path}
          onClick={() => {
            window.scrollTo(0, 0);
            setActiveNav(false);
          }}
          aria-label={name}
        >
          {name}
        </NavLink>
      </MNItem>
    </MNItemWrap>
  );

  useEffect(() => {
    if (activeNav) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [activeNav]);

  return (
    <MNWrap>
      {activeNav && (
        <MNOverlay>
          <MNItemList>
            {mainLinks.map((el, i) => createLink(el.path, el.name, i))}
          </MNItemList>

          <MNContactContainer>
            <MNContactWrap>
              <MNContactHeader> Kontakt </MNContactHeader>
              <MNContactText>T: {contactData.tel} </MNContactText>
              <MNContactText>E: {contactData.email} </MNContactText>
            </MNContactWrap>

            <MNContactWrap>
              <MNContactHeader>Social</MNContactHeader>
              <MNContactWrapIcons>
                <MNContactWrapIcon
                  aria-label='Facebook'
                  target='_blank'
                  rel='noopener noreferrer'
                  href={contactData.facebook}
                >
                  <FacebookIcon />
                </MNContactWrapIcon>
                <MNContactWrapIcon
                  aria-label='Instagram'
                  target='_blank'
                  rel='noopener noreferrer'
                  href={contactData.instagram}
                >
                  <InstagramIcon />
                </MNContactWrapIcon>
              </MNContactWrapIcons>
            </MNContactWrap>
          </MNContactContainer>
        </MNOverlay>
      )}
      <LogoImg
        src={require('../../../../assets/logo/LogoBiel200.png')}
        alt={'logo Tomasz Hajnos'}
      />
      <Hamburger open={activeNav} onClick={() => setActiveNav(!activeNav)} />
    </MNWrap>
  );
};
