import styled, { css, keyframes } from 'styled-components';

export const MNWrap = styled.nav`
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  padding: 4px;
`;
export const LogoImg = styled.img`
  height: 100px;
  position: relative;
`;

const OverlayAnimationTime = 300;

const OverlayAnimation = keyframes`
    from {
        bottom: 100%;
    }
    to {
        bottom: 0%;
    }
`;

export const MNOverlay = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0%;
  background: black;
  background-color: #000;
  animation: ${OverlayAnimation} ${OverlayAnimationTime}ms ease-in 1;
  overflow: hidden;
`;

export const MNItemList = styled.ul`
  margin-left: 15vw;

  @media (orientation: landscape) {
    margin-top: 15vh;
  }
  @media (orientation: portrait) {
    margin-top: 15vw;
  }
  @media (max-width: 430px) {
    margin-top: 130px;
  }
`;

export const MNItemSessionListHeader = styled.h2`
  margin: 30px 0 10px;
  letter-spacing: 1px;
`;

export const MNItemWrap = styled.div`
  overflow: hidden;
`;

const ItemAnimation = keyframes`
    from {
        transform: translateY(100%);
    }
    to{
        transform: translateY(0%);
    }
`;

const ItemContactAnimation = keyframes`
    0% {
        overflow: hidden;
        bottom: -100vh
    }
    100%{
        bottom:0;
    }
`;

export const MNItem = styled.p(
  ({ delay }) => css`
    animation: ${ItemAnimation} ${OverlayAnimationTime + 400 + delay * 100}ms
      ease;
    padding: 8px;
    font-size: 34px;

    @media (max-width: 640px) and (orientation: landscape) {
      font-size: 24px;
    }

    a {
      position: relative;

      :hover {
        ::after {
          width: 100%;
          height: 2px;
        }
      }
    }

    a::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      background: #30dafb;
      border-radius: 20px;
      transition: 0.4s;
      transition-timing-function: ease;
      transition-delay: 20ms;
    }
  `,
);

export const MNContactContainer = styled.footer`
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  animation: ${ItemContactAnimation} ${OverlayAnimationTime + 900}ms ease;
`;

export const MNContactWrap = styled.div`
  margin: 0 24px 16px 12px;
`;
export const MNContactHeader = styled.h4`
  margin-bottom: 8px;
`;
export const MNContactText = styled.p`
  margin-bottom: 8px;
  text-indent: 8px;
  cursor: pointer;
`;
export const MNContactWrapIcons = styled.div`
  display: flex;
`;
export const MNContactWrapIcon = styled.a`
  display: block;
  padding: 8px;
`;
