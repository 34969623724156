import { AboutMe } from '../../../molecules/aboutMe/AboutMe';
import { AppLayout } from '../../../molecules/layouts/AppLayout';
import { categories } from '../../../../constatns/categories';
import { ImageNav } from '../../../molecules/nav/imageNav/ImageNav';
import { MainHeader } from '../../../molecules/header/MainHeader';
import { RecordsGrid } from '../../../molecules/recordsGrid/RecordsGrid';
import { shuffle } from '../../../../helpers/shufle';

export const Home = () => {
  return (
    <AppLayout>
      <MainHeader />
      <ImageNav />
      <AboutMe />
      <RecordsGrid categories={shuffle(categories)} />
    </AppLayout>
  );
};
