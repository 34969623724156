import styled from 'styled-components';

export const AboutMeWrap = styled.main`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 76px 0;
`;

export const AboutMeImgWrap = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 500px;
`;

export const AboutMeImg = styled.img`
  border-radius: 6px;
  height: 70vh;
  max-width: 95%;
`;

export const AboutMeImgAuthor = styled.h1`
  padding: 36px 0 24px;
`;

export const AboutMeImgEmail = styled.h3``;

export const AboutMeTextWrap = styled.div`
  max-width: 500px;
  padding: 20px;
`;

export const AboutMeTextHeader = styled.h2`
  padding: 16px;
`;

export const AboutMeNormalTextCenter = styled.p`
  display: block;
  padding: 22px 0;
`;

export const AboutMeBgcWrap = styled.div`
  align-items: center;
  background-image: url(${(props) => props.img});
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  font-size: 32px;
  justify-content: center;
  margin-bottom: 36px;
  min-height: 60vh;
`;

export const AboutMeBgcQuote = styled.p`
  max-width: 600px;
  text-align: center;
`;

export const AboutMeBgcAuthorQuote = styled.p`
  font-size: 22px;
  max-width: 45%;
  transform: translate(55%, 70%);
`;
