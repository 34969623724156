import { importAll } from '../../helpers/images';

export const weddingSession = [
  {
    category: 'Sesja ślubna',
    title: 'Sesja plenerowa Agnieszki i Damiana',
    navImg: 1,
    mainImg: 0,
    images640: importAll(
      require.context(
        '../../assets/weddingSession/1/640x480',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
    images1024: importAll(
      require.context(
        '../../assets/weddingSession/1/1024x768',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
    images1600: importAll(
      require.context(
        '../../assets/weddingSession/1/1600x720',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
  },
  {
    category: 'Sesja ślubna',
    title: 'Wesele Eli i Grześka',
    navImg: 1,
    mainImg: 0,
    images640: importAll(
      require.context(
        '../../assets/weddingSession/2/640x480',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
    images1024: importAll(
      require.context(
        '../../assets/weddingSession/2/1024x768',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
    images1600: importAll(
      require.context(
        '../../assets/weddingSession/2/1600x720',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
  },
  {
    category: 'Sesja ślubna',
    title: 'Wesele Dagi i Karola',
    navImg: 1,
    mainImg: 15,
    images640: importAll(
      require.context(
        '../../assets/weddingSession/3/640x480',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
    images1024: importAll(
      require.context(
        '../../assets/weddingSession/3/1024x768',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
    images1600: importAll(
      require.context(
        '../../assets/weddingSession/3/1600x720',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
  },
  {
    category: 'Sesja ślubna',
    title: 'Ślub Asi i Maćka',
    navImg: 1,
    mainImg: 3,
    images640: importAll(
      require.context(
        '../../assets/weddingSession/4/640x480',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
    images1024: importAll(
      require.context(
        '../../assets/weddingSession/4/1024x768',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
    images1600: importAll(
      require.context(
        '../../assets/weddingSession/4/1600x720',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
  },
  {
    category: 'Sesja ślubna',
    title: 'Wesele Karoliny i Rafała',
    navImg: 0,
    mainImg: 0,
    images640: importAll(
      require.context(
        '../../assets/weddingSession/5/640x480',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
    images1024: importAll(
      require.context(
        '../../assets/weddingSession/5/1024x768',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
    images1600: importAll(
      require.context(
        '../../assets/weddingSession/5/1600x720',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
  },
];
