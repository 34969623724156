import { importAll } from '../../helpers/images';

export const christeningSession = [
  {
    category: 'Chrzest',
    title: 'Chrzest Liliany',
    navImg: 4,
    mainImg: 0,
    images640: importAll(
      require.context(
        '../../assets/christening/1/640x480',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
    images1024: importAll(
      require.context(
        '../../assets/christening/1/1024x768',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
    images1600: importAll(
      require.context(
        '../../assets/christening/1/1600x720',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
  },
  {
    category: 'Chrzest',
    title: 'Chrzest Hani',
    navImg: 0,
    mainImg: 0,
    images640: importAll(
      require.context(
        '../../assets/christening/2/640x480',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
    images1024: importAll(
      require.context(
        '../../assets/christening/2/1024x768',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
    images1600: importAll(
      require.context(
        '../../assets/christening/2/1600x720',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
  },
  {
    category: 'Chrzest',
    title: 'Chrzest Kajtka',
    navImg: 0,
    mainImg: 0,
    images640: importAll(
      require.context(
        '../../assets/christening/4/640x480',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
    images1024: importAll(
      require.context(
        '../../assets/christening/4/1024x768',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
    images1600: importAll(
      require.context(
        '../../assets/christening/4/1600x720',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
  },
  {
    category: 'Chrzest',
    title: 'Chrzest Gosi',
    navImg: 4,
    mainImg: 4,
    images640: importAll(
      require.context(
        '../../assets/christening/3/640x480',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
    images1024: importAll(
      require.context(
        '../../assets/christening/3/1024x768',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
    images1600: importAll(
      require.context(
        '../../assets/christening/3/1600x720',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
  },
];
