import { importAll } from '../../helpers/images';

export const carSession = [
  {
    category: 'Samochody',
    title: 'Ballin SPOT',
    navImg: 6,
    mainImg: 6,
    images640: importAll(
      require.context(
        '../../assets/carSession/1/640x480',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
    images1024: importAll(
      require.context(
        '../../assets/carSession/1/1024x768',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
    images1600: importAll(
      require.context(
        '../../assets/carSession/1/1600x720',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
  },
  {
    category: 'Samochody',
    title: 'Moc możliwości',
    navImg: 1,
    mainImg: 0,
    images640: importAll(
      require.context(
        '../../assets/carSession/2/640x480',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
    images1024: importAll(
      require.context(
        '../../assets/carSession/2/1024x768',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
    images1600: importAll(
      require.context(
        '../../assets/carSession/2/1600x720',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
  },
];
