import styled from 'styled-components';

export const Wrap = styled.footer`
  align-content: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  text-align: center;

  > * {
    width: 33%;
  }

  @media (max-width: 640px) {
    flex-direction: column;

    > * {
      width: 100%;
    }
  }
`;

export const Header = styled.h3`
  font-size: 30px;
  padding: 20px;
`;

export const Text = styled.p`
  align-self: center;
  padding: 14px;
`;

export const LinksWrap = styled.div`
  align-content: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (max-width: 640px) {
    padding-top: 16px;
  }
`;

export const Button = styled.div`
  padding: 6px;
`;

export const Anchor = styled.a``;

export const LinksText = styled.p`
  padding: 10px;
  width: 100%;

  @media (max-width: 640px) {
    width: auto;
  }
`;
