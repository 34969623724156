import { importAll } from '../helpers/images';

export const headerImg = [
  {
    images640: importAll(
      require.context(
        '../assets/background/640x480',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
    images1024: importAll(
      require.context(
        '../assets/background/1024x768',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
    images1600: importAll(
      require.context(
        '../assets/background/1600x720',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
  },
];

export const authorBgcImg = [
  {
    images640: importAll(
      require.context(
        '../assets/background/authorBgc/640-480',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
    images1024: importAll(
      require.context(
        '../assets/background/authorBgc/1024-768',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
    images1600: importAll(
      require.context(
        '../assets/background/authorBgc/1600-720',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
  },
];

export const quoteBgcImg = [
  {
    images640: importAll(
      require.context(
        '../assets/background/quoteBgc/640-480',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
    images1024: importAll(
      require.context(
        '../assets/background/quoteBgc/1024-768',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
    images1600: importAll(
      require.context(
        '../assets/background/quoteBgc/1600-720',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
  },
];

export const contactBgcImg = [
  {
    images640: importAll(
      require.context(
        '../assets/background/contact/640',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
    images1024: importAll(
      require.context(
        '../assets/background/contact/1024',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
    images1600: importAll(
      require.context(
        '../assets/background/contact/1600',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
  },
];
