import { getNavImg } from '../helpers/images';

import { carSession } from './categories/carSession';
import { christeningSession } from './categories/christeningSession';
import { communionSession } from './categories/communionSession';
import { familySession } from './categories/familySession';
import { otherSession } from './categories/otherSession';
import { partnerSession } from './categories/partnerSession';
import { portraitSession } from './categories/portraitSession';
import { weddingSession } from './categories/weddingSession';

export const mainLinks = [
  {
    path: '/',
    name: 'Start',
  },
  {
    path: '/kategoria',
    name: 'Sesje zdjęciowe',
  },
  {
    path: '/o mnie',
    name: 'O mnie',
  },
  {
    path: '/FAQ',
    name: 'FAQ',
  },
  {
    path: '/kontakt',
    name: 'Kontakt',
  },
];

export const categoryLinks = [
  {
    path: '/kategoria/Sesja ślubna',
    name: 'Sesja ślubna',
    img: getNavImg(weddingSession),
  },
  {
    path: '/kategoria/Sesja partnerska',
    name: 'Sesja partnerska',
    img: getNavImg(partnerSession),
  },
  {
    path: '/kategoria/Sesja rodzinna',
    name: 'Sesja rodzinna',
    img: getNavImg(familySession),
  },
  {
    path: '/kategoria/Portret',
    name: 'Portret',
    img: getNavImg(portraitSession),
  },
  {
    path: '/kategoria/Komunia',
    name: 'Komunia',
    img: getNavImg(communionSession),
  },
  {
    path: '/kategoria/Chrzest',
    name: 'Chrzest',
    img: getNavImg(christeningSession),
  },
  {
    path: '/kategoria/Inne',
    name: 'Inne',
    img: getNavImg(otherSession),
  },
  {
    path: '/kategoria/Samochody',
    name: 'Samochody',
    img: getNavImg(carSession),
  },
];
