import styled from 'styled-components';

export const Wrap = styled.nav`
  display: flex;
  flex-wrap: wrap;
  padding: 54px 0;
`;

export const ImgsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-size: 22px;
  justify-content: space-evenly;
  padding: 36px 8px;
`;

export const ImgWrap = styled.a`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 300px;
  justify-content: center;
  padding: 10px;
  position: relative;
  width: 300px;

  @media (min-width: 900px) {
    height: 350px;
    width: 350px;
  }
`;

export const Img = styled.img`
  border-radius: 4px;
  height: 100%;
  max-width: 100%;
  object-fit: cover;
  width: 100%;
`;

export const ImgText = styled.h3`
  font-size: inherit;
  mix-blend-mode: screen;
  position: absolute;
  text-align: center;
  top: 85%;
  width: 100%;
`;
