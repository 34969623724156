import { importAll } from '../../helpers/images';

export const communionSession = [
  {
    category: 'Komunia',
    title: 'Komunia Damiana i Amelii',
    navImg: 10,
    mainImg: 10,
    images640: importAll(
      require.context(
        '../../assets/communion/1/640x480',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
    images1024: importAll(
      require.context(
        '../../assets/communion/1/1024x768',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
    images1600: importAll(
      require.context(
        '../../assets/communion/1/1600x720',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
  },
];
