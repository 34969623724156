import { carSession } from './categories/carSession';
import { christeningSession } from './categories/christeningSession';
import { communionSession } from './categories/communionSession';
import { familySession } from './categories/familySession';
import { otherSession } from './categories/otherSession';
import { partnerSession } from './categories/partnerSession';
import { portraitSession } from './categories/portraitSession';
import { weddingSession } from './categories/weddingSession';

export const categories = [
  ...carSession,
  ...christeningSession,
  ...communionSession,
  ...familySession,
  ...otherSession,
  ...partnerSession,
  ...portraitSession,
  ...weddingSession,
];
