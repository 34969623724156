import { contactData } from '../../../constatns/aboutMeTexts';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import {
  Anchor,
  Button,
  Header,
  LinksText,
  LinksWrap,
  Text,
  Wrap,
} from './Footer.styled';

export const Footer = () => {
  return (
    <Wrap>
      <Header>Tomasz Hajnos</Header>
      <Text>Copyright © 2022 Tomasz Hajnos Fotografia.</Text>

      <LinksWrap>
        <Button>
          <Anchor
            aria-label='Facebook'
            target='_blank'
            rel='noopener noreferrer'
            href={contactData.facebook}
          >
            <FacebookIcon />
          </Anchor>
        </Button>
        <Button>
          <Anchor
            aria-label='Instagram'
            target='_blank'
            rel='noopener noreferrer'
            href={contactData.instagram}
          >
            <InstagramIcon />
          </Anchor>
        </Button>
        <LinksText>T: {contactData.tel}</LinksText>
      </LinksWrap>
    </Wrap>
  );
};
