import { Footer } from '../../../atoms/footer/Footer';
import { MainNavigation } from '../../../molecules/nav/mainNav/MainNavigation';
import {
  NotFoundContainer,
  NotFoundText,
  NotFoundWrap,
} from './NotFound.styled';

export const NotFound = () => {
  return (
    <NotFoundContainer>
      <MainNavigation />
      <NotFoundWrap>
        <NotFoundText>Strona nie znaleziona...</NotFoundText>
      </NotFoundWrap>
      <Footer />
    </NotFoundContainer>
  );
};
