import { Img, ImgWrap, TextHeader, TextWrap, Wrap } from './Record.styled';

export const Record = ({ record: { category, images640, mainImg, title } }) => {
  return (
    <Wrap>
      <ImgWrap
        href={'/kategoria/' + category + '/' + title}
        aria-label={'/kategoria/' + category + '/' + title}
      >
        <Img img={images640[mainImg || 0]} alt={category + '/' + title} />
      </ImgWrap>

      <TextWrap>
        <TextHeader>{title}</TextHeader>
      </TextWrap>
    </Wrap>
  );
};
