import styled from 'styled-components';

export const Article = styled.article`
  margin: 120px auto 30px auto;
  max-width: 1024px;
`;

export const HeaderWrap = styled.header``;

export const HeaderImgWrap = styled.div`
  display: flex;
  justify-content: center;
`;

export const HeaderImg = styled.img`
  border-radius: 4px;
  max-width: 100%;
`;

export const Header = styled.h1`
  padding: 48px 8px 16px;
  text-indent: 16px;
`;

export const Gap = styled.div`
  height: 200px;
`;

export const Section = styled.section`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding: 16px;
`;

export const Img = styled.img`
  border-radius: 4px;
  max-width: 100%;
`;
