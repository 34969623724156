import { importAll } from '../../helpers/images';

export const familySession = [
  {
    category: 'Sesja rodzinna',
    title: 'Rodzinna sesja świąteczna',
    navImg: 0,
    mainImg: 0,
    images640: importAll(
      require.context(
        '../../assets/familySession/1/640x480',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
    images1024: importAll(
      require.context(
        '../../assets/familySession/1/1024x768',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
    images1600: importAll(
      require.context(
        '../../assets/familySession/1/1600x720',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
  },
  {
    category: 'Sesja rodzinna',
    title: 'Sesja świąteczna',
    navImg: 1,
    mainImg: 4,
    images640: importAll(
      require.context(
        '../../assets/familySession/2/640x480',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
    images1024: importAll(
      require.context(
        '../../assets/familySession/2/1024x768',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
    images1600: importAll(
      require.context(
        '../../assets/familySession/2/1600x720',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
  },
  {
    category: 'Sesja rodzinna',
    title: 'Sesja rodzinna',
    navImg: 1,
    mainImg: 5,
    images640: importAll(
      require.context(
        '../../assets/familySession/3/640x480',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
    images1024: importAll(
      require.context(
        '../../assets/familySession/3/1024x768',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
    images1600: importAll(
      require.context(
        '../../assets/familySession/3/1600x720',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
  },
];
