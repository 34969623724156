import { createGlobalStyle } from 'styled-components';

export const GlobalStyled = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    html{
        scroll-behavior: smooth;
        font-family: 'Roboto', 'Arial';
    }
    
    body{
        background-color: ${({ theme }) => theme.colors.backgroundColor};
        color: ${({ theme }) => theme.colors.fontColor};
        min-height: 100vh;
    }

    ul,li{
        list-style: none;
    }

    a{
        color:inherit;
        text-decoration: none;
    };
`;
