import { aboutMeTexts } from '../../../../constatns/aboutMeTexts';
import { AppLayout } from '../../../molecules/layouts/AppLayout';
import { quoteBgcImg } from '../../../../constatns/backgrounds';
import { useResize } from '../../../../hooks/useResize';
import {
  AboutMeBgcAuthorQuote,
  AboutMeBgcQuote,
  AboutMeBgcWrap,
  AboutMeImg,
  AboutMeImgAuthor,
  AboutMeImgEmail,
  AboutMeImgWrap,
  AboutMeNormalTextCenter,
  AboutMeTextHeader,
  AboutMeTextWrap,
  AboutMeWrap,
} from './AboutMePage.styled';

export const AboutMe = () => {
  const { size } = useResize();

  const { author, email, title, text1, text2, quote, authorQuote } =
    aboutMeTexts;

  return (
    <AppLayout>
      <AboutMeWrap>
        <AboutMeImgWrap>
          <AboutMeImg src={aboutMeTexts[size][0]} alt={'author image'} />
          <AboutMeImgAuthor>{author}</AboutMeImgAuthor>
          <AboutMeImgEmail>{email}</AboutMeImgEmail>
        </AboutMeImgWrap>
        <AboutMeTextWrap>
          <AboutMeTextHeader> {title} </AboutMeTextHeader>
          <AboutMeNormalTextCenter> {text1} </AboutMeNormalTextCenter>
          <AboutMeNormalTextCenter> {text2} </AboutMeNormalTextCenter>
        </AboutMeTextWrap>
      </AboutMeWrap>
      <AboutMeBgcWrap img={quoteBgcImg[0][size][0]} alt={'background image'}>
        <AboutMeBgcQuote>{quote}</AboutMeBgcQuote>
        <AboutMeBgcAuthorQuote>{authorQuote}</AboutMeBgcAuthorQuote>
      </AboutMeBgcWrap>
    </AppLayout>
  );
};
