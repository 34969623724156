import { AppLayout } from '../../../molecules/layouts/AppLayout';
import { faqTexts } from '../../../../constatns/faq';
import {
  FaqArticle,
  FaqHead,
  FaqHeader,
  FaqHeaderHed,
  FaqHeaderText,
  FaqSection,
  FaqText,
  Wrap,
} from './Faq.styled';

export const Faq = () => {
  const texts = faqTexts.map((el, i) => (
    <FaqSection key={el.title + i}>
      <FaqHead>{el.title}</FaqHead>
      <FaqText>{el.text}</FaqText>
    </FaqSection>
  ));

  return (
    <AppLayout>
      <FaqArticle>
        <FaqHeader>
          <FaqHeaderHed>Często zadawane pytania</FaqHeaderHed>
          <FaqHeaderText>Najlepsz pytania i odpowiedzi</FaqHeaderText>
        </FaqHeader>
        <Wrap>{texts.slice(0, texts.length / 2)}</Wrap>
        <Wrap>{texts.slice(texts.length / 2)}</Wrap>
      </FaqArticle>
    </AppLayout>
  );
};
