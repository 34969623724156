import { categoryLinks } from '../../../../constatns/links';
import { Img, ImgsWrap, ImgText, ImgWrap, Wrap } from './ImageNav.styled';
import { useEffect } from 'react';
import AOS from 'aos';

export const ImageNav = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <Wrap>
      <ImgsWrap>
        {categoryLinks.map((el) => (
          <ImgWrap
            key={el.path}
            href={el.path}
            aria-label={el.name}
            data-aos='fade-up'
            data-aos-duration='1500'
          >
            <Img src={el.img} alt={'background image'} loading={'lazy'} />
            <ImgText>{el.name}</ImgText>
          </ImgWrap>
        ))}
      </ImgsWrap>
    </Wrap>
  );
};
