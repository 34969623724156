import { importAll } from '../../helpers/images';

export const otherSession = [
  {
    category: 'Inne',
    title: 'Iskra',
    navImg: 6,
    mainImg: 6,
    images640: importAll(
      require.context(
        '../../assets/otherSession/1/640x480',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
    images1024: importAll(
      require.context(
        '../../assets/otherSession/1/1024x768',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
    images1600: importAll(
      require.context(
        '../../assets/otherSession/1/1600x720',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
  },
  {
    category: 'Inne',
    title: 'Body attack',
    navImg: 2,
    mainImg: 2,
    images640: importAll(
      require.context(
        '../../assets/otherSession/2/640x480',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
    images1024: importAll(
      require.context(
        '../../assets/otherSession/2/1024x768',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
    images1600: importAll(
      require.context(
        '../../assets/otherSession/2/1600x720',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
  },
];
