import { AppLayout } from '../../../molecules/layouts/AppLayout';
import { CategoriesPageHeader } from './CategoriesPage.styled';
import { ImageNav } from '../../../molecules/nav/imageNav/ImageNav';

export const CategoriesPage = () => {
  return (
    <AppLayout>
      <CategoriesPageHeader>Kategorie</CategoriesPageHeader>
      <ImageNav />
    </AppLayout>
  );
};
