import { importAll } from '../../helpers/images';

export const portraitSession = [
  {
    category: 'Portret',
    title: 'Sesja ciążowa Sylwii',
    navImg: 1,
    mainImg: 0,
    images640: importAll(
      require.context(
        '../../assets/portraitSession/2/640x480',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
    images1024: importAll(
      require.context(
        '../../assets/portraitSession/2/1024x768',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
    images1600: importAll(
      require.context(
        '../../assets/portraitSession/2/1600x720',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
  },
  {
    category: 'Portret',
    title: 'Sesja portretowa Kasi i Sary',
    navImg: 1,
    mainImg: 0,
    images640: importAll(
      require.context(
        '../../assets/portraitSession/3/640x480',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
    images1024: importAll(
      require.context(
        '../../assets/portraitSession/3/1024x768',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
    images1600: importAll(
      require.context(
        '../../assets/portraitSession/3/1600x720',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
  },
  {
    category: 'Portret',
    title: 'Sesja mamy i synka',
    navImg: 1,
    mainImg: 0,
    images640: importAll(
      require.context(
        '../../assets/portraitSession/4/640x480',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
    images1024: importAll(
      require.context(
        '../../assets/portraitSession/4/1024x768',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
    images1600: importAll(
      require.context(
        '../../assets/portraitSession/4/1600x720',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
  },
  {
    category: 'Portret',
    title: 'Sesja Moniki',
    navImg: 1,
    mainImg: 0,
    images640: importAll(
      require.context(
        '../../assets/portraitSession/5/640x480',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
    images1024: importAll(
      require.context(
        '../../assets/portraitSession/5/1024x768',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
    images1600: importAll(
      require.context(
        '../../assets/portraitSession/5/1600x720',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
  },
  {
    category: 'Portret',
    title: 'Sesja Justyny i Marysi',
    navImg: 0,
    mainImg: 0,
    images640: importAll(
      require.context(
        '../../assets/portraitSession/6/640x480',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
    images1024: importAll(
      require.context(
        '../../assets/portraitSession/6/1024x768',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
    images1600: importAll(
      require.context(
        '../../assets/portraitSession/6/1600x720',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
  },
];
