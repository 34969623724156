import { Record } from '../../atoms/record/Record';
import { Wrapper } from './RecordsGrid.styled';

export const RecordsGrid = ({ categories }) => {
  return (
    <Wrapper>
      {categories.map((el, i) => (
        <Record key={el.category + el.title + i} record={el} />
      ))}
    </Wrapper>
  );
};
