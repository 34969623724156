import styled from 'styled-components';

export const FaqArticle = styled.article`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 58px auto;
  max-width: 1024px;
`;

export const FaqHeader = styled.header`
  flex-basis: 100%;
  padding: 64px 0 36px;
  text-align: center;
`;

export const FaqHeaderHed = styled.h1`
  padding: 8px;
`;

export const FaqHeaderText = styled.p`
  font-style: italic;
`;

export const Wrap = styled.div`
  flex-basis: 45%;

  @media (max-width: 640px) {
    flex-basis: 95%;
  }
`;

export const FaqSection = styled.section`
  padding: 24px 12px;
`;

export const FaqHead = styled.h2`
  font-style: italic;
`;

export const FaqText = styled.p`
  border-bottom: 1px solid ${({ theme }) => theme.colors.secondary};
  padding: 12px 0;
  text-indent: 10px;
`;
