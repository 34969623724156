import styled, { css } from 'styled-components';

const HamburgerStyle = css`
  width: 45px;
  height: 40px;
  position: relative;
  cursor: pointer;
  z-index: 9999;
`;

export const HamburgerWrap = styled.div(
  ({ open }) => css`
    ${HamburgerStyle}

    ${open &&
    css`
      > div {
        width: 0;
        height: 0;
      }
      > div::after {
        top: 0;
        left: -5px;
        transform: rotate(-45deg);
      }
      > div::before {
        top: 0px;
        left: -5px;
        transform: rotate(45deg);
      }
    `}
  `,
);

const defaultPseudoCss = css`
  content: '';
  position: absolute;
  width: 30px;
  height: 3px;
  background: #30dafb;
  border-radius: 50px;
  transition: 0.4s;
`;

export const HamburgerService = styled.div`
  top: 50%;
  left: 10px;

  ${defaultPseudoCss};

  &:before {
    top: -10px;
    left: -5px;

    ${defaultPseudoCss};
  }

  &:after {
    top: 10px;
    left: -5px;

    ${defaultPseudoCss};
  }
`;
