import { importAll } from '../../helpers/images';

export const partnerSession = [
  {
    category: 'Sesja partnerska',
    title: 'Sesja partnerska Eweliny i Bartka',
    navImg: 3,
    mainImg: 3,
    images640: importAll(
      require.context(
        '../../assets/partnerSession/2/640x480',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
    images1024: importAll(
      require.context(
        '../../assets/partnerSession/2/1024x768',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
    images1600: importAll(
      require.context(
        '../../assets/partnerSession/2/1600x720',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
  },
  {
    category: 'Sesja partnerska',
    title: 'Sesja partnerska Dominiki i Dominika',
    navImg: 9,
    mainImg: 9,
    images640: importAll(
      require.context(
        '../../assets/partnerSession/1/640x480',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
    images1024: importAll(
      require.context(
        '../../assets/partnerSession/1/1024x768',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
    images1600: importAll(
      require.context(
        '../../assets/partnerSession/1/1600x720',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
  },
  {
    category: 'Sesja partnerska',
    title: 'Sesja partnerska Angeliki i Piotrka',
    navImg: 1,
    mainImg: 0,
    images640: importAll(
      require.context(
        '../../assets/partnerSession/3/640x480',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
    images1024: importAll(
      require.context(
        '../../assets/partnerSession/3/1024x768',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
    images1600: importAll(
      require.context(
        '../../assets/partnerSession/3/1600x720',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    ),
  },
];
