import styled, { css } from 'styled-components';

export const Wrap = styled.div(
  ({ img }) => css`
    background-attachment: fixed;
    background-image: url(${img});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    margin-bottom: 64px;
  `,
);

export const TextWrap = styled.div`
  align-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  text-align: center;
  width: 100%;
`;

export const TextHeader = styled.h2`
  flex-basis: 100%;
  padding: 30px;
`;
export const Text = styled.p`
  margin: 0 auto;
  max-width: 80%;
  padding: 20px 5%;
`;
