import { AppLayout } from '../../../molecules/layouts/AppLayout';
import { contactBgcImg } from '../../../../constatns/backgrounds';
import { contactData } from '../../../../constatns/aboutMeTexts';
import { useEffect } from 'react';
import { useResize } from '../../../../hooks/useResize';
import AOS from 'aos';
import {
  Header,
  HeaderSubtitle,
  HeaderText,
  HeaderTitle,
  ImgWrap,
  InfoAnchor,
  InfoColumn,
  InfoPrefix,
  InfoText,
  InfoWrapper,
} from './Contact.styled';

export const Contact = () => {
  const { size } = useResize();

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <AppLayout>
      <ImgWrap url={contactBgcImg[0][size][0]} alt={'background image'} />
      <Header>
        <HeaderTitle>Skontaktuj się!</HeaderTitle>
        <HeaderSubtitle>
          Odpowiadam na wszystkie wysłane do mnie wiadomości od razu gdy mam
          możliwość!
        </HeaderSubtitle>
        <HeaderText>
          Napisz do mnie bezpośrednio: {contactData.email}
        </HeaderText>
      </Header>

      <InfoWrapper>
        <InfoColumn>
          <InfoPrefix>Tel:</InfoPrefix>
          <InfoText data-aos='zoom-in' data-aos-duration='500'>
            {contactData.tel}
          </InfoText>
        </InfoColumn>
        <InfoColumn>
          <InfoPrefix>Email:</InfoPrefix>
          <InfoText data-aos='zoom-in' data-aos-duration='700'>
            {contactData.email}
          </InfoText>
        </InfoColumn>
        <InfoColumn>
          <InfoPrefix>Adress:</InfoPrefix>
          <InfoText data-aos='zoom-in' data-aos-duration='900'>
            {contactData.address}
          </InfoText>
        </InfoColumn>
        <InfoColumn>
          <InfoPrefix>Facebook</InfoPrefix>
          <InfoAnchor
            aria-label='Facebook'
            data-aos='zoom-in'
            data-aos-duration='1100'
            target='_blank'
            rel='noopener noreferrer'
            href={contactData.facebook}
          >
            Zobacz
          </InfoAnchor>
        </InfoColumn>
        <InfoColumn>
          <InfoPrefix>Instagram</InfoPrefix>
          <InfoAnchor
            aria-label='Instagram'
            data-aos='zoom-in'
            data-aos-duration='1300'
            target='_blank'
            rel='noopener noreferrer'
            href={contactData.instagram}
          >
            Zobacz
          </InfoAnchor>
        </InfoColumn>
      </InfoWrapper>
    </AppLayout>
  );
};
